import {useEffect,useContext,useState, Key} from 'react';

import { IonButtons,IonButton, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,
  IonGrid,IonCol,IonRow,IonItem,IonIcon,
  useIonModal,
  useIonToast, 
  IonCheckbox,
  IonDatetime} from '@ionic/react';
import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';


import {AppContext} from '../global';

import '../common/styles.css';
import styles from '../common/styles.module.css';

import {useHistory, useLocation} from 'react-router';

import {restDelete, restPost, useRestSearchByOrder} from '../hooks/AdminHttp';

import { Icon } from 'ionicons/dist/types/components/icon/icon';
import { logoIonic } from 'ionicons/icons';
import GenericPost from '../modal/GenericPost';
import GenericDetail from '../modal/GenericDetail';
import { Json } from '../common/types';

import moment from 'moment';
import GenericImport from '../modal/GenericImport';

import {Ini2bin} from './GenericListExt';


const GenericList: React.FC<Json> = ({meta}) => {

  // const { name } = useParams<{ name: string; }>();

  const {data,setData,status,setStatus,entities} = useContext(AppContext);

  const history = useHistory();


  const [filter,setFilter] = useState({});
  const [orderField,setOrderField] = useState('created_date');

  const [result,search] = useRestSearchByOrder(meta.search_path);

  const [toastPresent,toastDismiss] = useIonToast();

  const [presentDetail, dismissDetail] = useIonModal(GenericDetail, {
    meta:meta,
    item: status.item,
    entities:entities,
    onCloseClicked: ()=>{
      search(filter,0,20,orderField);
      dismissDetail();
    }
  });


  const [presentPost, dismissPost] = useIonModal(GenericPost, {
    meta:meta,
    entities:entities,
    onCloseClicked: ()=>{
      search(filter,0,20,orderField);
      dismissPost();
    }
  });

  const [presentImport, dismissImport] = useIonModal(GenericImport, {
    meta:meta,
    onCloseClicked: ()=>{
      search(filter,0,20,orderField);
      dismissImport();
    }
  });

  useEffect(()=>{
    search(filter,0,20,orderField);

    console.log(JSON.stringify(entities));
  },[]);


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonMenuButton  slot="start" />
          <IonTitle>{meta.name}</IonTitle>
          <IonButtons slot="end">
            {
              meta.actions.map((e:string)=>{
                if ('import'=== e){
                  return <IonButton  onClick={()=>{
                    presentImport();
                  }}>导入{meta.name}</IonButton>
                }else if ('create' === e){
                  return <IonButton  onClick={()=>{
                    presentPost();
                  }}>新增{meta.name}</IonButton>
                }else if ('refresh' === e){
                  return <IonButton  onClick={()=>{
                    search(filter, 0, 20 ,orderField);
                  }}><IonIcon icon={logoIonic}></IonIcon>
                  </IonButton>
                }
              })
            }


            
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        
        <IonGrid className={styles.pagiGrid}>
        <IonRow className={styles.pagiHead}>
            {
              meta.list_fields.map((key:string)=>{

                let field = meta.fields[key];

                if (field.type == 'bool'){


                  return <IonCol style={{'cursor':'pointer'}} key={key } size={field.size?field.size:1} onClick={()=>{
                    setOrderField(key);
                    search(filter,result.page,20,key);
                  }}>{field.name}</IonCol>
                }else{

                  return <IonCol style={{'cursor':'pointer'}} key={key } size={field.size?field.size:2} onClick={()=>{
                    setOrderField(key);
                    search(filter,result.page,20,key);
                  }}>{field.name}</IonCol>
                }

              })
            }

          <IonCol size="1">操作</IonCol>
        </IonRow>
        {
          result.items.map((item:any,i:number)=>(
            <IonRow key={i} className={styles.pagiLine}>

            {
              meta.list_fields.map((key:string)=>{
                let field = meta.fields[key];

                if (field.type == 'bool'){
                  return <IonCol key={key} size={field.size?field.size:1}>
                      <IonCheckbox checked={item[key]} disabled={true}></IonCheckbox>
                    </IonCol>
                }else if (field.type == 'date'){
                  return <IonCol key={key} size={field.size?field.size:2}>{ moment(item[key]).format("YY-MM-DD HH:mm:SS") }</IonCol>

                }else if (field.type == 'foreign' && field.cached){
                  console.log('item.key: ' + item[key]);
                  console.log('item.keyss: ' + key);
                  console.log('item.keyss: ' + field.key);
                  console.log('entities:' + JSON.stringify(entities));
                  if (field.key == undefined) field.key = key;

                  if (item[key]){

                    return <IonCol key={key} size={field.size?field.size:2}>{entities[field.key + 's'][item[key]].name}</IonCol>
                  }else{
                    return <IonCol key={key} size={field.size?field.size:2}></IonCol>
                  }
                }else{

                  return <IonCol key={key} size={field.size?field.size:2}>{item[key] }</IonCol>
                }

              })
            }


              <IonCol size="1">
                <IonButton size="small" fill="outline" onClick={()=>{
                  setStatus({...status,item:item});
                  presentDetail();
                }}>详情</IonButton>
              <IonButton size="small" fill="outline" onClick={()=>{
                  restDelete(meta.delete_path + '/' + item.uuid,()=>{
                    search(filter,0,20,orderField);
                  });
                }}>删除</IonButton>

              {
                meta.copyable &&  <IonButton size="small" fill="outline" onClick={()=>{
                  let tmp:Json = {};
                  Object.assign(tmp,item);
                  tmp ['uuid'] = null;
                  // tmp ['created_date'] = null;
                  // tmp ['updated_date'] = null;

                  restPost(meta.post_path,null,tmp,(resp:any)=>{
                    console.log('resp: ' + resp);
                    toastPresent({
                      message:"复制成功", 
                      duration: 3000,
                      color:'success' 
                    });


                    search(filter,0,20,orderField);
      
                  },(msg:any)=>{
                    toastPresent({
                      message:msg, 
                      duration: 3000,
                      color:'danger' 
                    });
                  });
                }}>复制</IonButton>
              } 

              {
                meta.extends && meta.extends.indexOf('ini2bin') >= 0 &&
                <Ini2bin item={item}/>
              } 
                
              </IonCol> 
            </IonRow>
          ))
        }

        </IonGrid>
        <IonItem lines="none" className={styles.pagiFoot}>
          { result.page>0 &&
          <IonButton slot="start" fill="outline" onClick={()=>search(filter,result.page-1,20,orderField)}>上一页</IonButton>
          }
          { result.page < result.pages-1 &&
          <IonButton slot="end" fill="outline" onClick={()=>search(filter,result.page+1,20,orderField)}>下一页</IonButton>
          }
        </IonItem>

      </IonContent>
    </IonPage>
  );
};

export default GenericList;
